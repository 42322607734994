<template>
    <div class="grid">
        <!-- ESTA VISTA ES EL EDIT -->
        <div class="col-12">
            <Card>
                <template #title>
                    <div class="grid">
                        <div class="col">
                            {{ career.carrera }}
                        </div>
                        <div class="col flex  justify-content-end ">
                            <Button label="Adicionar Asignatura" severity="secondary" icon="pi pi-plus" @click="openDialog()" />
                            <div class="pr-2"></div>
                            <SplitButton :label="`${current_period?.periodo === 'Anual'?'':`${current_period?.periodo} -` } ${current_period?.gestion}`" icon="pi pi-clock" severity="info"/>
                            <!-- <SplitButton :label="`${current_period.periodo} - ${current_period.gestion}`" icon="bx bx-calendar"   :model="items"  v-if="current_period" /> -->
                        </div>
                    </div>
                    
                </template>
                <template #subtitle> {{ career.institucion_educativa }} </template>
                <template #content>
                    <div class="flex justify-content-start">
                        <div class="labeltext"> <span class="title">Area de Formación:</span> <span class="content"> {{career.area  }}</span> </div>
                        <div class="labeltext"> <span class="title"> Carga Horaria:</span> <span class="content"> {{ career.carga_horaria }}</span> </div>
                        <div class="labeltext"> <span class="title"> Tiempo:</span> <span class="content"> {{ career.tiempo_estudio }}</span> </div>
                        <div class="labeltext"> <span class="title"> Regimen:</span> <span class="content"> {{ career.regimen_estudio }}</span> </div>
                      
                    </div>
                    <Divider></Divider>
                    <div class="grid">
                        <div class="col-6" v-for="(asignatura,index) in subjects" :key="index">
                            <Panel :header="` ${asignatura.planEstudioAsignatura.regimenGradoTipo.regimenGrado}: ${asignatura.planEstudioAsignatura.asignaturaTipo.asignatura}  `"  toggleable
                              :pt="{
                                header: { class: 'bg-secondary' },
                                title: { class: 'text-white' },
                                toggler: { class: 'text-white hover:bg-primary-reverse' },
                                }"
                            >
                            <template #icons>
                                <button class="p-panel-header-icon p-link mr-2 kicon-panel-info" @click="editSubject(asignatura)">
                                    <i class='bx bxs-pencil'></i>
                                </button>
                            </template>

                            
                               <strong>{{ asignatura.planEstudioAsignatura.asignaturaTipo.abreviacion }}</strong>  Horas: {{ asignatura.planEstudioAsignatura.horas }}

                               <div v-for="(aula,index2) in asignatura.aulas " :key="index2">
                                    <div class="card border border-1   text-dark mb-3  bg-light rounded-top"  v-if="aula.paraleloTipo?.id !== 49" >
                                        <div class="card-header  bg-keyrus-dark   " > 
                                            <div class="grid">
                                                <div class="col flex flex-column">
                                                    <span> Paralelo: {{ aula.paraleloTipo?.paralelo}} </span>
                                                    <span> Cupo: {{ aula.cupo }} </span>
                                                    <span> Turno: {{ aula?.turnoTipo?.turno }} </span>
                                                    
                                                    
                                                </div>
                                                <div class="col flex  justify-content-end ">
                                                    <div>
                                                        <Dropdown v-model="aula.teacher" :options="teachers" filter optionLabel="nombre"
                                                        placeholder="Asignar Docente"/>
                                                    </div>
                                                    <div>
                                                        <Calendar v-model="aula.fecha_inicio" dateFormat="dd/mm/yy" class="w-7rem border-round" placeholder="Fecha Inicio" v-tooltip.bottom="'Fecha Inicio del Docente'" />
                                                    </div>
                                                    <div>
                                                        <Calendar v-model="aula.fecha_fin" dateFormat="dd/mm/yy" class="w-7rem border-round" placeholder="Fecha Fin"  v-tooltip.bottom="'Fecha Fin del Docente'" />
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                        <!-- {{aula}} -->
                                        <table class="table">
                                            <thead>
                                                <th>Aula</th>
                                                <th>Dia</th>
                                                <th>Hora Inicio</th>
                                                <th>Hora Fin</th>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(dia,index3) in aula.aulasDetalles  "  :key="index3">
                                                    <td>{{ dia.numeroAula }} </td>
                                                    <td>{{ dia.diaTipo.dia }}</td>
                                                    <td>{{ dia.horaInicio }}</td>
                                                    <td>{{ dia.horaFin }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                               </div>
                               <!-- {{ JSON.stringify(asignatura) }} -->
                            </Panel>


                        </div>
                    </div>


                </template>

                <template #footer>
                    <Button icon="pi pi-check" label="Guardar" @click="assignTeachers()" />
                    <Button icon="pi pi-times" label="Cancelar" severity="secondary" style="margin-left: 0.5em" @click="returnShow() " />
                </template>
            </Card>
        </div>  

        <AcademicOfferEdit :dialog="dialog" :title="title" :subject="subject"  @closeDialog="closeDialog"  @updateData="updateData" ref="AcademicOfferEdit" ></AcademicOfferEdit>
    </div>
    
</template>
<script>

import CareerDataServie from '@/services/CareerDataServie';
import AcademicOffertDataService from '@/services/AcademicOffertDataService';
import AcademicOfferEdit from '@/components/AcademicOfferEdit.vue';
import _ from 'lodash';
export default {
    data: () => ({
        career: {},
        resolution_study: {},
        current_period: null,
        subjects:[],
        teachers: [],
        is_edit:false,
        items: [
                {
                    label: '2023',
                    icon: 'bx bxs-calendar',
                    command: () => {
                        this.toast.add({ severity: 'success', summary: 'Updated', detail: 'Se Cambio de agestion 2023', life: 3000 });
                    }
                },
                ],
        dialog:false,
        subject:{},
        asignaturas:[],
        title:""
    }),
    mounted() {
        this.getCareer()
    },
    methods: {

        async openDialog()
        {
            this.asignaturas = []
            this.subject = {aulas:[],eliminado_aulas:[],eliminado_detalles:[]}
            this.subject.planEstudioAsignatura = {regimenGradoTipo:{}}
            this.title = "Adicionar Oferta"
            this.dialog = true

        },

        closeDialog(dialog)
        {
            console.log('dialog',dialog)
            this.dialog = dialog
            
        },
        async updateData()
        {
             this.getCareer()
             
        },


        async editSubject(subject)
        {   
            let edit_subject =  _.cloneDeep(subject)
            edit_subject.eliminado_aulas = []
            edit_subject.eliminado_detalles = []            

            let response = await AcademicOffertDataService.getSubjectsStudyPlanByRegimenId(this.$route.params.plan_estudio_carrera_id,edit_subject.planEstudioAsignatura.regimenGradoTipo.id)
          
            this.asignaturas = response.data.data
            
            let asignaturaTipo = _.find(response.data.data, (asignatura)=>{ return asignatura.asignatura_tipo_id === edit_subject.planEstudioAsignatura.asignaturaTipo.id})
            
            edit_subject.planEstudioAsignatura.asignaturaTipo = asignaturaTipo

            this.subject = edit_subject
           
            this.$refs.AcademicOfferEdit.setAsignaturas( this.asignaturas)
            this.title = "Editar Oferta"
            this.dialog = true
        },

        getCareer() {
            CareerDataServie.get(this.$route.params.career_id)
                .then((response) => {
                    this.career = response.data.data
                    this.getCurrentPeriod()
                })
        },
        async getCurrentPeriod() {
            
            let response = await CareerDataServie.getCurrentPeriod(this.$route.params.career_id)
                console.log(response.data.data)    
            this.current_period = response.data.data
                await this.getTeachers()
                      this.getSubjects()
                
        },

        async getSubjects()
        {
            let response = await AcademicOffertDataService.getShowSubjects(this.$route.params.plan_estudio_carrera_id,this.current_period.gestion, this.current_period.periodo_tipo_id)
            console.log('response ',response.data.data)
            let has_docente = true
            if(response.data.data.length==0)
            {
               response = await  AcademicOffertDataService.getSubjects(this.$route.params.plan_estudio_carrera_id,this.current_period.gestion, this.current_period.periodo_tipo_id)
               has_docente = false
               console.log(response.data)
            }
            console.log('has docente',has_docente)
            this.subjects = response.data.data
            console.log('subjects',this.subjects)
            if(has_docente)
            {
                this.subjects.forEach(subject => {
                    
                    subject.aulas.forEach(aula => {
                        let docente_id = null
                        let fecha_inicio = ''
                        let fecha_fin = ''
                        

                    aula.aulasDocentes.forEach(aula_docente => {
                        if(aula_docente.bajaTipoId === 0)  //se especifica que docente esta activo 
                        {
                            fecha_fin = aula_docente.asignacionFechaFin
                            fecha_inicio = aula_docente.asignacionFechaInicio
                            docente_id = aula_docente.maestroInscripcion.id
                        }

                    });
                    aula.fecha_inicio = fecha_inicio
                    aula.fecha_fin = fecha_fin
                    aula.teacher = _.find(this.teachers, (teacher)=>{ return teacher.id === docente_id})
                    return aula 
                    });

                    return subject
                });
            }
        },

        async getTeachers()
        {
            // AcademicOffertDataService.getTeachers(this.career.ie_id,this.current_period.gestion, this.current_period.periodo_tipo_id)
            AcademicOffertDataService.getTeachers(this.career.ie_id,this.current_period.gestion, 53)
                .then((response)=>{
                    console.log('teachers', response)
                    this.teachers = [] //clean teachers
                    response.data.data.forEach(docente => {
                        this.teachers.push({id: docente.id , nombre: `${docente.paterno} ${docente.materno} ${docente.nombre}` })
                    });
                    
                })

        },
        returnShow()
        {
            this.$router.push(`/academic_offert_show_teacher/${this.$route.params.plan_estudio_carrera_id}/${this.$route.params.career_id}`)
        },

        assignTeachers()
        {
            let payload = []
            
            this.subjects.forEach(subject => {
            
                subject.aulas.forEach(aula => {
                    if(aula.teacher && aula.fecha_inicio && aula.fecha_fin)
                    {
                        payload.push({
                            aula_id: aula.id,
                            maestro_inscripcion_id: aula.teacher.id,
                            fecha_inicio: aula.fecha_inicio,
                            fecha_fin: aula.fecha_fin
    
                        })
                    }
                });
            });
            
            AcademicOffertDataService.assingTeachers(payload)
                .then((response)=>{
                    console.log(response.data)
                    this.$router.push(`/academic_offert_show_teacher/${this.$route.params.plan_estudio_carrera_id}/${this.$route.params.career_id}`)
                })
        }
    },
    components:{
        AcademicOfferEdit
    }


}
</script>