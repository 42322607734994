<template>
  <div class="grid">

    <div class="col-12">
      <Card>
        <template #title>{{ career.carrera }} </template>
        <template #subtitle> {{ career.institucion_educativa }} </template>
        <template #content>
          <strong> Area de Formación:</strong> {{ career.area }} <br>
          <strong> Carga Horaria:</strong> {{ career.carga_horaria }}
          <Divider></Divider>
          <h3>OFERTA ACADEMICA</h3>
          
          <div v-for="(item, index) in interval_times" :key="index">

            <!-- <Panel :header="item.name" toggleable 
                    :pt="{
                            header: { class: 'bg-secondary' },
                            title: { class: 'text-white' },
                            toggler: { class: 'text-white hover:bg-secondary-reverse' }
                        }"
                > -->
            <Panel :header="item.name" toggleable :pt="{
              header: { class: 'bg-secondary' },
              title: { class: 'text-white' },
              toggler: { class: 'text-white hover:bg-primary-reverse' },
            }">
              <template #icons>
                <button class="p-panel-header-icon text-white hover:bg-primary-reverse  p-link mr-2"
                  @click="item.list.push({ paralelos: [] })" v-tooltip.bottom="'Adicionar Asignatura'">
                  <!-- <i class='bx bxs-file-plus'></i> -->
                  <span class="bx bxs-file-blank"></span>
                </button>
              </template>

              <div class="card border border-1   text-dark mb-3  bg-light rounded-top"
                v-for="(course, index2) in item.list" :key="index2">
                <div class="card-header  bg-keyrus-dark   ">
                  <div class="grid">
                    <div class="col">
                      Asignatura:
                      <Dropdown v-model="course.subject" :options="item.subjects" optionLabel="asignatura"
                        @change="isEmpty(course.paralelos)" placeholder="Seleccione Asignatura" />
                    </div>
                    <div class="col flex justify-content-end ">
                      <!-- <i class='bx bx-folder-plus' ></i> -->
                      <Button icon="bx bx-notepad" severity="primary" text @click="course.paralelos.push({ days: [] })"
                        v-tooltip.bottom="'Adicionar Paralelo'" />
                      <Button icon="bx bxs-trash-alt" label="" severity="danger" text
                        @click="removeItem(item.list, index2)" v-tooltip.bottom="'Eliminar Asignatura'" />
                    </div>
                  </div>

                </div>
                <div class="card-body" >
                  <div class="card border border-1   text-dark mb-3  bg-light rounded-top "
                    v-for="(paralelo, index3) in course.paralelos" :key="index3">
                    <div class="card-header    bg-keyrus  ">
                      <div class="grid">
                        <div class="col">
                          Paraleo:
                          <Dropdown v-model="paralelo.paralelo" :options="parallels" filter optionLabel="paralelo"
                            placeholder="Seleccione paralelo" />
                          Cupo:
                          <InputText type="text" class="w-5rem border-round" v-model="paralelo.cupo" />
                        </div>
                        <div class="col flex justify-content-end ">
                          <!-- <i class='bx bx-folder-plus' ></i> -->
                          <Button icon="bx bxs-plus-circle" label="" severity="primary" text
                            @click="paralelo.days.push({})" v-tooltip.bottom="'Adicionar Horario'" />
                          <Button icon="bx bxs-trash-alt" label="" severity="danger" text
                            @click="removeItem(course.paralelos, index3)" v-tooltip.bottom="'Eliminar Paralelo'" />
                        </div>

                      </div>

                    </div>
                    <div class="card-body bg-keyrus-body">
                      <table class="table ">
                        <thead class="thead-dark">
                          <tr>

                            <th scope="col">N° Aula</th>
                            <th scope="col">Dia</th>
                            <th scope="col">Hora Inicio</th>
                            <th scope="col">Hora Fin</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(day, index4) in paralelo.days" :key="index4">
                            <td>
                              <InputText type="text" class="w-3rem border-round" v-model="day.aula" />
                            </td>
                            <td>
                              <Dropdown v-model="day.dia" :options="days" optionLabel="dia"
                                placeholder="Seleccione Dia" />

                            </td>
                            <td>
                              <Calendar id="calendar-timeonly" v-model="day.hora_inicio" timeOnly
                                class="w-5rem border-round" />
                            </td>
                            <td>
                              <Calendar id="calendar-timeonly" v-model="day.hora_fin" timeOnly
                                class="w-5rem border-round" />
                            </td>
                            <td>
                              <Button icon="bx bxs-trash-alt" label="" severity="danger" text
                                @click="removeItem(paralelo.days, index4)" v-tooltip.bottom="'Eliminar horario'" />
                            </td>

                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>

                </div>
              </div>

              <!-- <Button icon="pi pi-plus" label="Adicionar" severity="secondary"  @click="item.list.push({code:1,name:'',hours:0})"  /> -->
              <!-- {{ JSON.stringify(item) }} -->
              <!-- <table class="table ">
                    <thead class="thead-dark">
                    <tr>
                        <th scope="col">Asignatura</th>
                        <th scope="col">Paralelo</th>
                        <th scope="col">Cupo</th>
                        <th scope="col">N° Aula</th>
                        <th scope="col">Dias</th>
                        <th scope="col">Hora Inicio</th>
                        <th scope="col">Hora Fin</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(course,index2) in item.list" :key="index2">
                        <td> 
                          <Dropdown v-model="course.subject" :options="item.subjects" optionLabel="asignatura" placeholder="Seleccione Asignatura"  />
                        </td>
                        <td>
                          <Dropdown v-model="course.paralelo" :options="parallels" filter optionLabel="paralelo" placeholder="Seleccione paralelo"  />
                        </td>
                        <td>
                          <InputText type="text" class="w-3rem border-round" v-model="course.cupo"/>
                        </td>
                        <td>
                          <InputText type="text" class="w-3rem border-round" v-model="course.aula"/>
                        </td>
                        <td>
                          <MultiSelect v-model="course.days" display="chip"  :options="days" optionLabel="sigla" placeholder="Seleccionar Dias" :maxSelectedLabels="5"  />
                        </td>
                      
                        <td>
                            <Calendar id="calendar-timeonly" v-model="course.hora_inicio" timeOnly class="w-5rem border-round" />
                        </td>
                        <td>
                            <Calendar id="calendar-timeonly" v-model="course.hora_fin" timeOnly class="w-5rem border-round" />
                        </td>
                        <td>
                            <Button icon="pi pi-trash" label="" severity="danger"  @click="removeItem(item.list,index2)"  />
                        </td>
                    </tr>
                    </tbody>
                </table> -->
            </Panel>
            <br>
          </div>

        </template>
        <template #footer>
          <Button icon="pi pi-check" label="Guardar" @click="saveAcademicOffert()" />
          <Button icon="pi pi-times" label="Cancelar" severity="secondary" style="margin-left: 0.5em" @click="returnCareer()" />
        </template>
      </Card>
    </div>
    <div class="col-4">

    </div>
  </div>
</template>
<script>
import CareerDataServie from '@/services/CareerDataServie';
import StudyRegimenDataService from '@/services/StudyRegimenDataService';
import ParallelDataService from '@/services/ParallelDataService';
import DayDataService from '@/services/DayDataService';
import AcademicOffertDataService from '@/services/AcademicOffertDataService';
export default {
  data: () => ({
    career: {},
    resolution_study: {},
    interval_times: [],
    parallels: [],
    days: [],
    current_period: {},
    instituto_plan_studio_carrera: {},
  

  }),
  mounted() {
    this.getCareer()
    // console.log('id',this.$route.params.plan_estudio_carrera_id)
    this.getParallels()
    this.getDays()
    this.getCurrentPeriod()
    this.getInstitutoPlanEstudioCarrera()
  },
  methods: {
    getCareer() {
      CareerDataServie.get(this.$route.params.career_id)
        .then((response) => {
          this.career = response.data.data
          this.setIntervals()
        })
    },
    getInstitutoPlanEstudioCarrera()
    {
      AcademicOffertDataService.getInstitutoPlanStudioCarrera(this.$route.params.plan_estudio_carrera_id)
        .then((response) => {
          this.instituto_plan_studio_carrera = response.data.data


        } ) 
    },
    getCurrentPeriod() {
      CareerDataServie.getCurrentPeriod(this.$route.params.career_id)
        .then((response) => {
          this.current_period = response.data.data
          console.log(response.data)
        })
    },
    getParallels() {
      ParallelDataService.getAll()
        .then((response) => {
          // console.log(response.data)
          this.parallels = response.data
        })
    },
    async setIntervals() {
      // console.log("iniciando conversion")
      let count = this.career.tiempo_estudio
      for (let i = 1; i <= count; i++) {

        let response = await StudyRegimenDataService.getRegimeDegree(`${this.getLiteral(i)} ${this.career.regimen_estudio}`)
        //console.log(response.data)
        if (response.data.statusCode == 200) {
          let regimen_grado = {
            plan_estudio_carrera_id: this.$route.params.resolution_plan_id,
            regimen_grado_tipo_id: response.data.data.id,
            name: `${response.data.data.regimenGrado}`,
            list: [],
          }
          let result = await StudyRegimenDataService.getSubjets(this.instituto_plan_studio_carrera.planEstudioCarreraId, response.data.data.id)
          regimen_grado.subjects = result.data.data

          this.interval_times.push(regimen_grado)
        } else {
          console.log(`no se encotro: ${this.getLiteral(i)} ${this.career.regimen_estudio}`)
        }


      }
    },
    removeItem(list, index) {
      list.splice(index, 1)
    },
    getDays() {
      DayDataService.getAll()
        .then((reponse) => {
          this.days = reponse.data
        })
    },
    isEmpty(list) {
      if (list.length < 1) {
        list.push({ days: [{}] })
      } else {
        console.log('no se agrego ', list.length)

      }
    },

    returnCareer() {
      console.log("Retornando")
      this.$router.push(`/career/${this.$route.params.career_id}`)
    },
    saveAcademicOffert() {
      let payload= []
      console.log('asdasda asdasdasd')
      this.interval_times.forEach(regimen_degree => {

      

        // let aulas = []
        regimen_degree.list.forEach(row => {

          let oferta_payload = {
            instituto_plan_estudio_carrera_id: this.$route.params.plan_estudio_carrera_id,
            gestion_tipo_id: this.current_period.gestion_tipo_id,
            periodo_tipo_id: this.current_period.periodo_tipo_id,
            plan_estudio_asignatura_id: row.subject.plan_estudio_asignatura_id,
            aulas:[]
        
          }

          row.paralelos.forEach(paralelo => {
            
              let paralelo_payload={
                cupo: paralelo.cupo,
                paralelo_tipo_id: paralelo.paralelo.id,
                detalles: []
              } 

              paralelo.days.forEach(dia => {
                let date = new Date(dia.hora_inicio)
                let inicio = `${date.getHours()}:${date.getMinutes()}`
                    date = new Date(dia.hora_fin)
                let fin = `${date.getHours()}:${date.getMinutes()}`

                let detalle_payload = {
                  dia_tipo_id : dia.dia.id,
                  hora_inicio : inicio,
                  hora_fin : fin,
                  numero_aula: dia.aula
                } 

                paralelo_payload.detalles.push(detalle_payload)
              });

              oferta_payload.aulas.push(paralelo_payload)


          });

          payload.push(oferta_payload)
          

       })
       
        // console.log(list_rows)
      })
      console.log(payload)
      AcademicOffertDataService.create(payload)
        .then((response)=>{
          console.log(response.data)
          this.$store.dispatch('showMessage',{severity:'success',title:'Oferta Academica',message:'Se guardo correctamente la oferta academica'})
          this.returnCareer()
        })
    },

    getLiteral(number) {
      let literal = ''
      switch (number) {
        case 1:
          literal = "PRIMER"
          break;
        case 2:
          literal = "SEGUNDO"
          break;
        case 3:
          literal = "TERCER"
          break;
        case 4:
          literal = "CUARTO"
          break;
        case 5:
          literal = "QUINTO"
          break;
        case 6:
          literal = "SEXTO"
          break;
        case 7:
          literal = "SEPTIMO"
          break;
        case 8:
          literal = "OCTAVO"
          break;
        case 9:
          literal = "NOVENO"
          break;
        case 10:
          literal = "DECIMO"
          break;


      }
      return literal
    }
  }

}
</script>